import { formatDateForURL, subtractHours } from "./DateFormat";

//const apiURL = "http://localhost:8080/api";
const apiURL = "https://data.prod.hiso2index.info/api";
const agencyHI = "HI1";
const agencyPA = "PAR";
let timeNow = new Date();
const dstNow = formatDateForURL(timeNow);
const dstYest = formatDateForURL(subtractHours(timeNow, 24));
// Prebuilt URLs for our calls
const fetchSitesHI = apiURL + "/site/getAllByAgencyCode?_agency_code=" + agencyHI;
const fetchSitesPA = apiURL + "/site/getAllByAgencyCode?_agency_code=" + agencyPA;
const fetchDataHI = apiURL + "/data/getMostRecentPM25andSO2?_agency_code=" + agencyHI + "&_dst_start=" + dstYest + "&_dst_end=" + dstNow;
const fetchDataPA = apiURL + "/data/getMostRecentPM25andSO2?_agency_code=" + agencyPA + "&_dst_start=" + dstYest + "&_dst_end=" + dstNow;

const sortByTime = (a, b) => {
	return (new Date(a.dateTimeStamp) - new Date(b.dateTimeStamp))*-1; //we want our array reversed newest at index 0
}

export function compareHours(currHour, pollutantHour) {
	// we are past midnight, so add 24; this works because we only pull 24 hours of data, or we'd have to use date too
	if (currHour < pollutantHour) {
		return currHour + 24 - pollutantHour;
	} else {
		return currHour - pollutantHour;
	}
}

export function isDataExpired(currHour, currMin, pollutantHour) {
	const dataOldAtHours = 3;
	const dataExpectedByMins = 35;
	const hoursLate = compareHours(currHour, pollutantHour);

	if (hoursLate >= dataOldAtHours) {
		if (hoursLate === dataOldAtHours && currMin <= dataExpectedByMins) { //in this case we don't expect data to have arrived yet
			return false;
		}
		return true;
	} else {
		return false;
	}
}

export async function fetchHistoricData(site, timeStart, timeEnd) {
	// TODO: Change this to a new, historic specific data fetch that uses site to further filter the data
	// Start date begins at 12am, End date ends at 11:59:59pm
	timeStart.setHours(0);
	timeStart.setMinutes(0);
	timeStart.setSeconds(0);
	timeEnd.setHours(23);
	timeEnd.setMinutes(59);
	timeEnd.setSeconds(59);
	const fetchDataURL = apiURL + "/data/getHistoricalData?_siteid=" + site.siteid + "&_dst_start=" + formatDateForURL(timeStart) + "&_dst_end=" + formatDateForURL(timeEnd);
	const response = await fetch(fetchDataURL, {
		method: "GET",
		mode: "cors"
	});
	const data = await response.json();
	return data;
}

export async function fetchDataAndCombine() {
	try {
		// We fetch the sites
		const response1 = await fetch(fetchSitesHI, {
			method: "GET",
			mode: "cors",
		});
		const response2 = await fetch(fetchSitesPA, {
			method: "GET",
			mode: "cors",
		});

		// We fetch the data
		const response3 = await fetch(fetchDataHI, {
			method: "GET",
			mode: "cors",
		});
		const response4 = await fetch(fetchDataPA, {
			method: "GET",
			mode: "cors",
		});

		const sitesHI = await response1.json()
		const sitesPA = await response2.json();
		const sites = {};
		for (const key in sitesHI) {
			sites[key] = sitesHI[key];
		}
		let newId = Object.keys(sites).length + 1;
		for (const key in sitesPA) {
			sites[newId] = sitesPA[key];
			++newId;
		}

		const dataHI = await response3.json();
		const dataPA = await response4.json();
		const data = {};
		for (const key in dataHI) {
			data[key] = dataHI[key];
		}
		newId = Object.keys(data).length + 1;
		for (const key in dataPA) {
			data[newId] = dataPA[key];
			++newId;
		}

		// We separate the data by pollutant
		const dataMap = new Map();
		Object.entries(data).forEach(([i, item]) => {
			const name = item.site;
			if (!dataMap.has(name)) {
				dataMap.set(name, { pm25: [], so2: [] });
			}
			if (item.pollutant === "PM2.5") {
				dataMap.get(name).pm25.push(item);
			} else if (item.pollutant === "SO2-15") {
				dataMap.get(name).so2.push(item);
			}
		});

		// We make the site and data smooch
		const combinedData = new Map();
		Object.entries(sites).forEach(([i, item]) => {
			const relatedData = dataMap.get(item.name) || { pm25: [], so2: [] };
			relatedData.pm25.sort(sortByTime);
			relatedData.so2.sort(sortByTime);

			let currHour = new Date(Date.now()).getUTCHours();
			const currMin = new Date(Date.now()).getUTCMinutes();

			if (relatedData.pm25.length > 0 ) {
				const pm25Hour = new Date(relatedData.pm25[0].dateTimeStamp).getHours();
				if (isDataExpired(currHour, currMin, pm25Hour)) {
					relatedData.pm25[0].expired = true;
				}
			}

			if (relatedData.so2.length > 0) {
				const so2Hour = new Date(relatedData.so2[0].dateTimeStamp).getHours();
				if (isDataExpired(currHour, currMin, so2Hour)) {
					relatedData.so2[0].expired = true;
				}
			}
			combinedData.set(i, { ...item, relatedData });
		});

		return combinedData;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error;
	}
}
