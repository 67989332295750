import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from "react";
//once AdvancedMarkerElement is available from https://www.npmjs.com/package/@react-google-maps/api migrate to that
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import NavBar from "./components/NavBar"; // import the NavBar component
import Advisory from "./components/Advisory"; // import the Advisory component
import {getMapIcon, getMapIconSize, worstPollutant, getValue, monitorTypeFromInstrumentType} from "./utils/Scale";
import DetailPanel from "./components/DetailPanel";
import AqiList from "./components/AqiList";
import { fetchDataAndCombine } from "./utils/FetchDataAndCombine";
import "./styles/App.css";

/***************************************************************************************************************************************************************
 * If the Hawaii DOH needs to display an advisory, set DISPLAY_ADVISORY to true AND fill in the advisory details below.
 **************************************************************************************************************************************************************/
const DISPLAY_ADVISORY = false;
const DISPLAY_ADVISORY_TITLE = "Air Quality Advisory";
const DISPLAY_ADVISORY_MESSAGE = "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
const DISPLAY_ADVISORY_LINK_PROMPT = "More info: ";
const DISPLAY_ADVISORY_LINK_TEXT = "Placeholder for AirNow";
const DISPLAY_ADVISORY_LINK_HREF = "https://www.airnow.com";
/***************************************************************************************************************************************************************
 * If the Hawaii DOH needs to display an advisory, set DISPLAY_ADVISORY to true AND fill in the advisory details above.
 **************************************************************************************************************************************************************/

// Our nav bar is 10% of the viewport, but capped at 128px. Subtract that from the viewport height to get the map height. This also allows us to save this value to use in the CSS.
let originalNavHeaderHeight = window.innerHeight * 0.1 < 128 ? "10vh" : "128px";
document.documentElement.style.setProperty("--nav-header-height", originalNavHeaderHeight);

// Scale factor compares screen size to original design sizes
document.documentElement.style.setProperty("--scale-factor", Math.min(Math.max(window.innerWidth / 1920, window.innerHeight / 1080), 1));
// Make sure this matches the above math statement
const scaleFactor = Math.min(Math.max(window.innerWidth / 1920, window.innerHeight / 1080), 1)

const mapContainerStyle = {
	height: `calc(100vh - ${originalNavHeaderHeight} - 5px)`,
	width: "100vw",
};
const monitorLayers = new Map([
	[
		"pm25regulatory",
		new Map([
			["enabled", true],
			["markers", []],
		]),
	],
	[
		"pm25nonregulatory",
		new Map([
			["enabled", true],
			["markers", []],
		]),
	],
	[
		"pm25temporary",
		new Map([
			["enabled", true],
			["markers", []],
		]),
	],
	[
		"so2regulatory",
		new Map([
			["enabled", true],
			["markers", []],
		]),
	],
	[
		"so2nonregulatory",
		new Map([
			["enabled", true],
			["markers", []],
		]),
	],
	[
		"so2temporary",
		new Map([
			["enabled", true],
			["markers", []],
		]),
	],
]);

const providerTypes = new Map([
	["Air Quality Agency", "regulatory"],
	["Other", "non-regulatory"],
	["Temporary", "temporary"],
	["PurpleAir", "purpleair"],
	["HI1", "regulatory"],
	["PAR", "non-regulatory"]
]);

const App = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [mapsLoaded, setMapsLoaded] = useState(false);
	const [windowSize, setWindowSize] = useState({
		width: "100%",
		height: "100vh",
	});
	const [navHeaderHeight, setNavHeaderHeight] = useState(originalNavHeaderHeight);
	const [combinedData, setCombinedData] = useState([]);
	const [selectedSite, setSelectedSite] = useState(null);
	const [selectedMonitorLayers, setSelectedMonitorLayers] = useState(monitorLayers);
	const [showAdaIcons, setShowAdaIcons] = useState(false);
	const [detailsPanelVisible, setDetailsPanelVisible] = useState(false);
	const [timer, setTimer] = useState(0);
	const [isTimerRunning, setIsTimerRunning] = useState(false);
	const detailsRef = useRef(null);
	const mapRef = useRef(null);
	const centerChangeTimeoutRef = useRef(null);

	const [mapSettings, setMapSettings] = useState({
		zoom: 8.4,
		center: { lat: 20.3, lng: -157.3 },
		mapTypeId: 'roadmap',
	});

	const handleMapUpdate = (setting, value) => {
		setMapSettings((prev) =>({
			...prev,
			[setting]: value
		}));
	};

	const handleCenterChanged = useCallback(() => {
		if (centerChangeTimeoutRef.current) {
			clearTimeout(centerChangeTimeoutRef.current);
		}
		centerChangeTimeoutRef.current = setTimeout(() => {
			if (mapRef.current) {
				const newCenter = mapRef.current.getCenter();
				setMapSettings((prev) => ({
					...prev,
					center: {
						lat: newCenter.lat(),
						lng: newCenter.lng()
					}
				}));
			}
		}, 500);
	}, []);

	const handleMapLoad = (loaded) => {
		setMapsLoaded(loaded);
	};

	const handleIdle = () => {
		// We need to hide Pegman because we don't like him!
		// We can't do this in isMapLoaded because the map is still in the process of rendering fullly.
		// As a precaution, the icon is directly above the pegman icon.
		let element = document.getElementsByClassName("gm-svpc")[0];
		if (element) {
			element.style.display = "none";
			element.style.visibility = "hidden";
		}
		// We also want to move the layer controls
		element = document.getElementsByClassName("gmnoprint gm-style-mtc-bbw")[0];
		if (element) {
			element.style.left = "";
			element.style.right = "75px !important";
		}
		// We also want to remove the full screen button
		element = document.getElementsByClassName("gm-control-active gm-fullscreen-control")[0];
		if (element) {
			element.style.display = "none";
			element.style.visibility = "hidden";
		}
	};

	const handleDataChange = (newData) => {
		setCombinedData(newData);
	};

	const handleSiteChange = (newSite) => {
		setSelectedSite(newSite);
	};

	const handleMarkerClick = (site) => {
		handleSiteChange(site);
	};

	const handleShownAdaIcons = () => {
		// It's a boolean toogle, so we don't need to pass a value
		setShowAdaIcons(!showAdaIcons);
	}

	const handleMonitorLayerChange = (newLayers) => {
		console.log(selectedMonitorLayers);
		selectedMonitorLayers.forEach((valueMap, key) => {
			for (let i = 0; i < newLayers.length; ++i) {
				if (key === newLayers[i][0]) {
					valueMap.set("enabled", !newLayers[i][1]);
				}	
			}
		});
		setSelectedMonitorLayers(selectedMonitorLayers);
		console.log(selectedMonitorLayers);
	};

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (detailsRef.current && !detailsRef.current.contains(event.target)) {
				setDetailsPanelVisible(false);
				setSelectedSite(null);
			}
		};
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	useEffect(() => {
		if (detailsPanelVisible) {
			setDetailsPanelVisible(detailsPanelVisible);
			if (selectedSite !== null) {
				setSelectedSite(null);
			}
		}
	}, [detailsPanelVisible]);

	useEffect(() => {
		let interval;
		if (isTimerRunning) {
		    interval = setInterval(() => {
				setTimer((prevTimer) => {
					if (prevTimer > 0) {
						return prevTimer - 60;
					} else {
						clearInterval(interval);
						return 0;
					}
				});
			}, 60000);
		}
		return () => clearInterval(interval);
	}, [isTimerRunning]);

	useEffect(() => {
		function populateLayers(data) {
			if (data) {
				Array.from(data, ([index, site]) => {
					if (site.agencycode !== "PAR" && site.aqscode !== "") {
						if (site.fullaqscode.startsWith("MM")) {
							// Temporary monitor
							if (site.relatedData.pm25.length > 0 && site.relatedData.so2.length > 0) {
								selectedMonitorLayers.get("pm25temporary").get("markers").push(site);
								selectedMonitorLayers.get("so2temporary").get("markers").push(site);
							} else if (site.relatedData.pm25.length > 0) {
								selectedMonitorLayers.get("pm25temporary").get("markers").push(site);
							} else if (site.relatedData.so2.length > 0) {
								selectedMonitorLayers.get("so2temporary").get("markers").push(site);
							}
						} else if (site.relatedData.pm25.length > 0 && site.relatedData.so2.length > 0) {
							selectedMonitorLayers.get("pm25regulatory").get("markers").push(site);
							selectedMonitorLayers.get("so2regulatory").get("markers").push(site);
						} else if (site.relatedData.pm25.length > 0) {
							selectedMonitorLayers.get("pm25regulatory").get("markers").push(site);
						} else if (site.relatedData.so2.length > 0) {
							selectedMonitorLayers.get("so2regulatory").get("markers").push(site);
						}
					} else if (site.agencycode === "PAR") {
						if (site.relatedData.pm25.length > 0) {
							selectedMonitorLayers.get("pm25nonregulatory").get("markers").push(site);
						}
					}
				});
			}
		}
		
		if (timer === 0) {
			fetchDataAndCombine()
			.then((data) => {
				handleDataChange(data);
				populateLayers(data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error processing data:", error);
				setIsLoading(false);
			});
			setTimer(300);
			setIsTimerRunning(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timer]);

	useLayoutEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
			setNavHeaderHeight(window.innerHeight * 0.1 < 128 ? "10vh" : "128px");
			document.documentElement.style.setProperty("--nav-header-height", navHeaderHeight);
			document.documentElement.style.setProperty("--scale-factor", Math.min(Math.min(window.innerWidth / 1920, window.innerHeight / 1080), 1));
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ height: windowSize.height, width: windowSize.width }}>
			{selectedMonitorLayers && (
				<NavBar
					monitorLayers={selectedMonitorLayers}
					setMonitorLayers={handleMonitorLayerChange}
					mapSettings={mapSettings}
					setMapSettings={handleMapUpdate}
					adaIcons={showAdaIcons}
					setAdaIcons={handleShownAdaIcons}
				/>
			)}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={() => handleMapLoad(true)}>
					{isLoading && <div>Loading...</div>}
					<div>
						<div>
							{DISPLAY_ADVISORY && (
								<Advisory
									title={DISPLAY_ADVISORY_TITLE}
									message={DISPLAY_ADVISORY_MESSAGE}
									linkPrompt={DISPLAY_ADVISORY_LINK_PROMPT}
									linkText={DISPLAY_ADVISORY_LINK_TEXT}
									linkHref={DISPLAY_ADVISORY_LINK_HREF}
								/>
							)}
						</div>
						<div>
							{selectedSite ? (
								<DetailPanel ref={detailsRef} advisory={DISPLAY_ADVISORY} detailHeight={windowSize.height} navHeaderHeight={navHeaderHeight} site={selectedSite} scaleFactor={scaleFactor}
								isVisible={detailsPanelVisible} setVisible={setDetailsPanelVisible} />
							) : (
								<div />
							)}
						</div>
					</div>
					{mapsLoaded && (
						<GoogleMap mapContainerStyle={mapContainerStyle} onIdle={handleIdle} center={mapSettings.center} zoom={mapSettings.zoom} mapTypeId={mapSettings.mapTypeId}
								   onLoad={(map) => (mapRef.current = map)} onCenterChanged={handleCenterChanged}>
							{Array.from(selectedMonitorLayers.entries()).map(
								([instrumentType, valueMap]) =>
									valueMap.get("enabled") &&
									valueMap.get("markers").map((instrument, index) => (
										<Marker
											key={instrumentType + index.toString()}
											position={{ lat: instrument.latitude, lng: instrument.longitude }}
											icon={{
												// Grab the current values for this monitor and its type to fetch the proper icon
												url: getMapIcon([instrument.relatedData.pm25[0], instrument.relatedData.so2[0]], instrumentType, showAdaIcons),
												scaledSize: new window.google.maps.Size(getMapIconSize(monitorTypeFromInstrumentType(instrumentType)), getMapIconSize(monitorTypeFromInstrumentType(instrumentType))),
												origin: new window.google.maps.Point(0, 0),
												anchor: new window.google.maps.Point(0, 0),
											}}
											title={instrument.name}
											onClick={() => handleMarkerClick(instrument)}
										/>
									))
							)}
						</GoogleMap>
					)}
					<AqiList showAdaIcons={showAdaIcons} />
				</LoadScript>
			</div>
		</div>
	);
};

// export default withAuthenticator(App);
export default App;
